import React from 'react'
import { Helmet } from 'react-helmet'

import SEO from '../components/seo'
import Press from '../components/Press'
import DigitalSteps from '../components/DigitalSteps'
import FAQs from '../components/FAQs'
import { HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'
import DigitalComptibility from '../components/DigitalComptibility'
import DigitalFeatures from '../components/DigitalFeatures'
import DigitalSatisfaction from '../components/DigitalSatisfaction'
import Hero from '../components/Hero'

class DigitalPage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Digital Fire Landing Page')
  }

  render() {
    return (
      <>
        <SEO
          title="Skylight Digital - Cast photos to your TV"
          description="Skylight Digital turns your any device in your home into a photo frame you can email photos to."
          path="/digital-fire/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <Hero page="digital-fire" />
          <DigitalComptibility title="SKYLIGHT DIGITAL IS COMPATIBLE WITH:" page="digital-fire" />
          <DigitalSteps page="fire" />
          <DigitalFeatures title="Other features" />
          <DigitalSatisfaction />
          <Press title="Skylight products have been featured in" page="digital" />
          <FAQs page="digital-fire" />
        </HideOverflow>
      </>
    )
  }
}

export default DigitalPage
