import styled from 'styled-components'
import { breakpoints, TwoColumnGrid } from 'src/utils/styles/global-styles'
import { GifRowContainer, CenteredGridLeft } from '../DynamicGifTiles/styles'
import { MediumHeader } from '../DigitalFeatures/styles'

export const Container = styled(GifRowContainer)`
  margin-top: 68px;
  margin-bottom: 36px;
  background-color: ${(props) => props.theme.cream};
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 40px;
    margin-bottom: 0px;
  }
`
export const ImageContainer = styled.div`
  width: 350px;
  @media (max-width: ${breakpoints.s}px) {
    width: 250px;
  }
`

export const GridLeft = styled(CenteredGridLeft)`
  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`
export const DigitalHeader = styled(MediumHeader)`
  width: 350px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    margin: 30px auto 15px;
    max-width: 90%;
  }
`

export const DigitalText = styled.p`
  font-family: 'FilsonProLight';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.09px;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  width: 420px;
  margin: 8px auto;
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    width: 90%;
    margin: 8px auto 40px;
  }
`
export const Grid = styled(TwoColumnGrid)`
  align-items: center;
`
