import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import bump from 'src/images/bump-logo.svg'
import {
  PressContainer,
  PressRow,
  RowTopOnMobile,
  RowBottomOnMobile,
  LogoContainer,
  Link,
  Header,
  Border,
  BumpLogo,
  BumpLink,
  DigitalHeader,
  DigitalLogoContainer,
  DigitalPressRow,
} from './styles'

function Content(props) {
  const { id, logo, link, description } = props
  return (
    <LogoContainer id={id}>
      <Link outline="0" href={link} target="_blank" rel="noopener noreferrer">
        <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt={description} />
      </Link>
    </LogoContainer>
  )
}

const Press = ({ title, page }) => {
  const { wallStreetLogo, todayShowLogo, buzzfeedLogo, yahooLogo, motherlyLogo, wired } =
    useStaticQuery(graphql`
      query PressQuery {
        wallStreetLogo: file(relativePath: { eq: "press/wsj.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        todayShowLogo: file(relativePath: { eq: "press/today-show-logo.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        buzzfeedLogo: file(relativePath: { eq: "press/buzzfeed-stacked-logo.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        yahooLogo: file(relativePath: { eq: "press/yahoo-logo.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        motherlyLogo: file(relativePath: { eq: "press/motherly.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        wired: file(relativePath: { eq: "redesign/wired.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)
  const digitalPress = [
    {
      logo: todayShowLogo,
      link: 'https://www.today.com/video/tree-dazzler-illuminated-sheers-twig-lights-and-other-holiday-tech-834920003980?v=b',
      description: 'Today Show Logo',
    },
    {
      logo: wallStreetLogo,
      link: 'https://www.wsj.com/articles/5-reasons-digital-picture-frames-are-finally-worth-buying-11602782129',
      description: 'Wall Street Logo',
    },
    {
      logo: buzzfeedLogo,
      link: 'https://www.buzzfeed.com/yiyang/splurge-worthy-mothers-day-gifts-shell-absolutely-adore',
      description: 'Buzzfeed Logo',
    },
    {
      logo: wired,
      link: 'https://www.wired.com/gallery/best-digital-picture-frames/',
      description: 'Wired Logo',
    },
  ]
  const isDigitalPage = page === 'digital'
  return (
    <PressContainer smallerPadding={isDigitalPage}>
      {isDigitalPage ? (
        <>
          <DigitalHeader>{title}</DigitalHeader>
          <DigitalPressRow>
            {digitalPress.map((press, i) => (
              <DigitalLogoContainer key={i}>
                <Content logo={press.logo} link={press.link} description={press.description} />
              </DigitalLogoContainer>
            ))}
          </DigitalPressRow>
        </>
      ) : (
        <>
          <Header>{title || 'AS FEATURED IN'}</Header>
          <PressRow>
            <RowTopOnMobile>
              <LogoContainer>
                <Content id="yahoo" logo={yahooLogo} description="Yahoo Logo" />
              </LogoContainer>
              <LogoContainer>
                <Content
                  id="motherly"
                  logo={motherlyLogo}
                  link="https://www.mother.ly/shop/products-to-help-organize/"
                  description="Motherly Logo"
                />
              </LogoContainer>
            </RowTopOnMobile>
            <RowBottomOnMobile>
              <LogoContainer id="bump">
                <BumpLink
                  outline="0"
                  href="https://www.thebump.com/a/mothers-day-guide"
                  target="_blank"
                  rel="noopener noreferrer"
                  inputMode="buzzfeed"
                >
                  <BumpLogo type="image/svg+xml" data={bump}>
                    svg-animation
                  </BumpLogo>
                </BumpLink>
              </LogoContainer>
              <LogoContainer>
                <Content
                  id="buzzfeed"
                  logo={buzzfeedLogo}
                  link="https://www.buzzfeed.com/yiyang/splurge-worthy-mothers-day-gifts-shell-absolutely-adore"
                  description="Buzzfeed Logo"
                />
              </LogoContainer>
            </RowBottomOnMobile>
          </PressRow>
        </>
      )}

      <Border />
    </PressContainer>
  )
}

Press.propTypes = {
  page: PropTypes.any,
  title: PropTypes.string,
}
Content.propTypes = {
  id: PropTypes.string,
  logo: PropTypes.object,
  link: PropTypes.string,
  description: PropTypes.string,
}
export default Press
