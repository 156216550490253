import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GridRight } from 'src/utils/styles/global-styles'
import { Container, ImageContainer, GridLeft, DigitalHeader, DigitalText, Grid } from './styles'

const DigitalSatisfaction = () => {
  const { moneyBackImg } = useStaticQuery(graphql`
    query DigitalSatisfactionQuery {
      moneyBackImg: file(relativePath: { eq: "money-back-guarantee.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <Container>
      <Grid>
        <GridLeft>
          <ImageContainer>
            <GatsbyImage
              image={moneyBackImg.childImageSharp.gatsbyImageData}
              alt="Money Back Guarantee"
            />
          </ImageContainer>
        </GridLeft>
        <GridRight>
          <DigitalHeader>100% Satisfaction Guaranteed</DigitalHeader>
          <DigitalText>
            We're confident that you'll love Skylight Digital, or your money back!
          </DigitalText>
        </GridRight>
      </Grid>
    </Container>
  )
}

export default DigitalSatisfaction
