import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import {
  Container,
  MediumHeader,
  AppImageContainer,
  Row,
  Textbox,
  TvImageContainer,
  CenteredRow,
  Header,
  Text,
} from './styles'

const DigitalSteps = ({ page }) => {
  const { stepOne, stepTwo } = useStaticQuery(graphql`
    query DigitalStepsQuery {
      stepOne: file(relativePath: { eq: "digital/step_one.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      stepTwo: file(relativePath: { eq: "digital/step_two.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const isFire = page === 'fire'
  return (
    <>
      <Container>
        <CenteredRow>
          <Header>How It Works</Header>
          <Text>
            No more cables and hacks — we’ve made it easy, regardless of how tech-savvy you are.
          </Text>
        </CenteredRow>
        <Row>
          <AppImageContainer>
            <GatsbyImage
              image={stepOne.childImageSharp.gatsbyImageData}
              alt="Display of photos uploading on the Skylight app."
            />
          </AppImageContainer>
          <Textbox>
            <MediumHeader blue>Step One:</MediumHeader>
            <MediumHeader>Upload photos directly</MediumHeader>
            <MediumHeader>from your phone</MediumHeader>
            <Text>
              Easily upload photos using our mobile app. Available on Apple and Android phones.
            </Text>
          </Textbox>
        </Row>
        <Row reverse>
          <Textbox>
            <MediumHeader blue>Step Two:</MediumHeader>
            <MediumHeader>Watch them show </MediumHeader>
            <MediumHeader>up in seconds</MediumHeader>
            {isFire ? (
              <Text>
                Download the Skylight app on your Fire device to instantly view your favorite
                memories in a photo slideshow.
              </Text>
            ) : (
              <Text>
                Open the Skylight app on your TV or tablet to instantly view your favorite memories
                in a photo slideshow.
              </Text>
            )}
          </Textbox>
          <TvImageContainer>
            <GatsbyImage
              image={stepTwo.childImageSharp.gatsbyImageData}
              alt="Photo of baby in fields on a tv screen."
            />
          </TvImageContainer>
        </Row>
      </Container>
      <CenteredRow blue>
        <Header>New to TV apps?</Header>
        <Text>
          Don't worry, we'll guide you through each step of the way so installation is only a matter
          of seconds!
        </Text>
      </CenteredRow>
    </>
  )
}

DigitalSteps.propTypes = {
  page: PropTypes.string,
}

export default DigitalSteps
