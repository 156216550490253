export const DIGITAL_FEATURES = [
  {
    alt: 'Video Player',
    title: 'Videos',
    text: 'Don’t stop at just photos — share videos of your favorite moments too!',
  },
  {
    alt: 'Pencil',
    title: 'Captions',
    text: 'Add context to your photos with easy-to-read captions.',
  },
  {
    alt: 'User',
    title: 'Family sharing',
    text: 'Invite friends and family to share photos via the mobile app or email.',
  },
]
