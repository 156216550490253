import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Analytics from 'src/utils/Analytics'
import { DIGITAL_FEATURES } from './constants'
import { GridLeft } from '../../utils/styles/global-styles'

import {
  Container,
  Row,
  Card,
  Textbox,
  Text,
  Title,
  ImageContainer,
  GrandCanyonImageContainer,
  AlbumImageContainer,
  AlbumsContainer,
  Grid,
  MediumHeader,
  RightGrid,
  Description,
  FeaturesHeader,
  MemoriesRow,
} from './styles'
import DigitalForm from '../DigitalForm'
import { DownloadButton } from '../DigitalForm/styles'

const DigitalFeatures = ({ title, page }) => {
  const { videoImg, captionImg, familyImg, grandCanyonApp, albumsApp } = useStaticQuery(graphql`
    query DigitalFeaturesQuery {
      videoImg: file(relativePath: { eq: "digital/videos.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      captionImg: file(relativePath: { eq: "digital/captions.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      familyImg: file(relativePath: { eq: "digital/family.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      grandCanyonApp: file(relativePath: { eq: "digital/grandCanyon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      albumsApp: file(relativePath: { eq: "digital/albums.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const images = [videoImg, captionImg, familyImg]
  const handleRedirectDigitalDeepLink = async (e) => {
    e.preventDefault()
    Analytics.track(`Added Digital to Cart`)
    window.location.href = 'https://skylightfamily.page.link/digital'
  }
  return (
    <>
      <AlbumsContainer>
        <Grid>
          <GridLeft>
            <AlbumImageContainer>
              <GatsbyImage
                image={albumsApp.childImageSharp.gatsbyImageData}
                alt="Albums view of Skylight app"
              />
            </AlbumImageContainer>
          </GridLeft>
          <RightGrid hideOnMobile>
            <DigitalForm
              page={page}
              section="albums"
              header="Plus, create albums for "
              secondaryHeader="different occasions"
              text="Organize and display your photos by event, location, or person with our albums feature."
              justify="center"
            />
          </RightGrid>
          <RightGrid>
            <MediumHeader>Plus, create albums for different occasions</MediumHeader>
            <Description>
              Organize and display your photos by event, location, or person with our albums
              feature.
            </Description>
          </RightGrid>
        </Grid>
      </AlbumsContainer>
      <FeaturesHeader>{title}</FeaturesHeader>
      <Container>
        <Row>
          {DIGITAL_FEATURES.map((feature, i) => (
            <React.Fragment key={i}>
              <Card>
                <ImageContainer>
                  <GatsbyImage
                    image={images[i].childImageSharp.gatsbyImageData}
                    alt={feature.alt}
                  />
                </ImageContainer>
                <Textbox>
                  <Title>{feature.title}</Title>
                  <Text>{feature.text}</Text>
                </Textbox>
              </Card>
            </React.Fragment>
          ))}
        </Row>
        <GrandCanyonImageContainer>
          <GatsbyImage
            image={grandCanyonApp.childImageSharp.gatsbyImageData}
            alt="Grand Canyon picture displayed on the Skylight app"
          />
        </GrandCanyonImageContainer>
      </Container>
      <MemoriesRow>
        <MediumHeader blue>Display your memories today.</MediumHeader>
        <DownloadButton type="button" onClick={handleRedirectDigitalDeepLink}>
          Try For Free
        </DownloadButton>
      </MemoriesRow>
    </>
  )
}

DigitalFeatures.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
}

export default DigitalFeatures
